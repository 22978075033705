var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
let InfosetsMoreListMembersMenu = class InfosetsMoreListMembersMenu extends Vue {
    constructor() {
        super(...arguments);
        this.menuOffsetWidth = 0;
    }
    goToInfosetAsMember(member) {
        this.$router.push({
            name: 'infoset',
            params: { infosetId: this.infosetId },
            query: { view_as: member.id },
        });
    }
    getMenuOffsetWidth() {
        const el = this.$refs['qmenu-more-member-actions-container'];
        this.menuOffsetWidth = el.offsetWidth;
    }
    removeMember(member) {
        return member;
    }
};
__decorate([
    Prop({ default: () => [] })
], InfosetsMoreListMembersMenu.prototype, "moreMembers", void 0);
__decorate([
    Prop({ default: '' })
], InfosetsMoreListMembersMenu.prototype, "infosetId", void 0);
__decorate([
    Emit('removeMember')
], InfosetsMoreListMembersMenu.prototype, "removeMember", null);
InfosetsMoreListMembersMenu = __decorate([
    Component
], InfosetsMoreListMembersMenu);
export default InfosetsMoreListMembersMenu;
