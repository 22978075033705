var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import InfosetsTableRow from './InfosetsTableRow.vue';
let InfosetsTable = class InfosetsTable extends Vue {
    constructor() {
        super(...arguments);
        this.thumbStyle = {
            right: '4px',
            borderRadius: '5px',
            backgroundColor: '#5d676a',
            width: '6px',
            opacity: 0.5,
        };
        this.barStyle = {
            right: '2px',
            borderRadius: '9px',
            backgroundColor: 'transparent',
            width: '9px',
            opacity: 0.2,
        };
    }
    exportPipedrive(data) {
        this.$emit('exportPipedrive', data);
    }
    exportPloomes(data) {
        this.$emit('exportPloomes', data);
    }
    setCurrentInfoset(infosetId) {
        return infosetId;
    }
    starInfoset(infosetStar) {
        return infosetStar;
    }
    editInfoset(infoset) {
        this.$emit('onEditInfoset', infoset);
    }
    recoverInfoSet(id) {
        this.$emit('onRecoverInfoset', id);
    }
    showStatsInfoset(infosetId) {
        this.$emit('showStatsInfoset', infosetId);
    }
};
__decorate([
    Prop({ default: () => [] })
], InfosetsTable.prototype, "infosets", void 0);
__decorate([
    Prop({ default: () => [] })
], InfosetsTable.prototype, "availableMembers", void 0);
__decorate([
    Prop({ default: false })
], InfosetsTable.prototype, "loadingToExportCsv", void 0);
__decorate([
    Prop({ default: '' })
], InfosetsTable.prototype, "tokenPipeDrive", void 0);
__decorate([
    Prop({ default: () => { } })
], InfosetsTable.prototype, "currentUser", void 0);
__decorate([
    Emit('setCurrentInfoset')
], InfosetsTable.prototype, "setCurrentInfoset", null);
__decorate([
    Emit('starInfoset')
], InfosetsTable.prototype, "starInfoset", null);
InfosetsTable = __decorate([
    Component({
        name: 'InfosetsTable',
        components: {
            InfosetsTableRow,
        },
    })
], InfosetsTable);
export default InfosetsTable;
