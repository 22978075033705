var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Vue, Component, Watch, PropSync } from 'vue-property-decorator';
let AccuracyDataFeedbackRange = class AccuracyDataFeedbackRange extends Vue {
    constructor() {
        super(...arguments);
        this.maxLabels = 10;
    }
    mounted() {
        this.changeInputBackground();
    }
    changeInputBackground() {
        /* #d9d9d9; */
        document.documentElement.style.setProperty('--track-backgroundSize', this.calcBackgroundSize());
    }
    calcBackgroundSize() {
        return this.currentValue * 10 - 5 + '% 100%';
    }
};
__decorate([
    PropSync('range', { default: 8 })
], AccuracyDataFeedbackRange.prototype, "currentValue", void 0);
__decorate([
    Watch('currentValue')
], AccuracyDataFeedbackRange.prototype, "changeInputBackground", null);
AccuracyDataFeedbackRange = __decorate([
    Component({})
], AccuracyDataFeedbackRange);
export default AccuracyDataFeedbackRange;
