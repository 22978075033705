var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { connect } from '@/overmind';
import InfosetExportProgress from '@/modules/infosets/components/Exportations/InfosetExportProgress.vue';
import { openDownloadExportFile } from '@/shared/utils/helpers';
import { removeAccentInName } from '@/shared/utils/helpers';
import { changeSymbolsByDashesInTheName } from '@/shared/utils/helpers';
let InfosetExportProgressContainer = class InfosetExportProgressContainer extends Vue {
    constructor() {
        super(...arguments);
        this.fileUrl = '';
        this.fileExtension = '.xlsx';
        this.exportationStatus = '';
        this.waitingStatus = true;
        this.loadingBtn = false;
        this.percentage = 0;
        this.verifyPercentage = '';
    }
    get loadingText() {
        if (this.currentTab === 'csv' || this.currentTab === 'excel')
            return 'A sua lista foi processada e está disponível para download';
        else
            return 'A sua lista foi processada e deve estar disponível na plataforma';
    }
    mounted() {
        this.updatePercentage();
        this.verifyPercentage = setInterval(() => {
            this.updatePercentage();
        }, 5000);
    }
    beforeDestroy() {
        clearInterval(this.verifyPercentage);
        this.$emit('loadingWaitingStatus', true);
        this.$emit('loadingCompleted', false);
    }
    finishProcess() {
        clearInterval(this.verifyPercentage);
        this.$emit('removeTabFromQueue', this.currentTab);
    }
    loadingWaitingStatus(value) {
        if (value)
            this.$emit('loadingWaitingStatus', value);
    }
    loadingCompleteStatus(val) {
        const completed = Boolean(this.exportationStatus.match('completed'));
        this.$emit('loadingCompleted', completed);
        if (completed)
            clearInterval(this.verifyPercentage);
    }
    removeTabFromQueue(tab) {
        this.$emit('removeTabFromQueue', tab);
    }
    onWaitingStatusChange(val) {
        if (val)
            this.$emit('loadingWaitingStatus', val);
    }
    onTabChange() {
        this.waitingStatus = true;
        this.updatePercentage();
    }
    async updatePercentage() {
        //@ts-ignore
        const result = await this.actions.getExportationByEventID({
            infosetId: this.infoset.id,
            eventID: this.eventId,
        });
        this.waitingStatus = false;
        if (result.status.match('cancelled')) {
            this.$q.notify({
                message: 'Exportação cancelada!',
                type: 'error',
            });
            this.finishProcess();
            return;
        }
        // TODO: refactor to small functions
        this.fileExtension = '.xlsx';
        this.fileUrl = result.params.fileUrl;
        if (result.params.uploadStats &&
            result.params.uploadStats.processed['rows'] &&
            result.params.uploadStats.processed['rows'] > 20000) {
            this.fileExtension = '.zip';
        }
        else {
            const service = result.params.service.split('-');
            if (service[0] === 'csv')
                this.fileExtension = '.csv';
        }
        this.percentage = this.calculatePercentage(result);
    }
    calculatePercentage(result) {
        if (!result || result.status.match('initialized'))
            return 0;
        this.exportationStatus = result.status;
        result = result.params;
        let getCurrent = 0;
        let getTotal = 0;
        const service = result.service.split('-');
        if (service[0] === 'csv' || service[0] === 'excel') {
            getCurrent = result.uploadStats.processed['rows'] || 0;
            getTotal = result.uploadStats.totals[service[1]];
        }
        else {
            getCurrent =
                result.uploadStats.processed.decisors +
                    result.uploadStats.processed.companies;
            getTotal =
                result.uploadStats.totals.decisors + result.uploadStats.totals.companies;
        }
        let calc = Math.round((getCurrent / getTotal) * 100);
        calc = calc >= 100 ? 99 : calc;
        return calc;
    }
    btnClicked() {
        if (!this.exportationStatus.match('completed')) {
            this.cancelExportation(this.eventId);
        }
        else {
            this.finishProcess();
        }
    }
    async cancelExportation(eventId) {
        // @ts-ignore
        const success = await this.actions.cancelExportation(eventId);
        if (success) {
            this.$q.notify({
                message: 'Exportação cancelada!',
                type: 'error',
            });
            this.finishProcess();
        }
        else {
            this.$q.notify({
                message: 'Não foi possível cancelar esta exportação!',
                type: 'error',
            });
        }
    }
    async downloadFile() {
        this.loadingBtn = true;
        let url = this.fileUrl.split('./exportations/')[1] +
            changeSymbolsByDashesInTheName(removeAccentInName(this.infoset.name.substring(0, 30))) +
            this.fileExtension;
        if (this.fileExtension == '.zip')
            url = url.replaceAll('-01', '');
        openDownloadExportFile(url);
        this.loadingBtn = false;
    }
};
__decorate([
    Prop({ default: '' })
], InfosetExportProgressContainer.prototype, "currentTab", void 0);
__decorate([
    Prop({ default: '' })
], InfosetExportProgressContainer.prototype, "eventId", void 0);
__decorate([
    Prop({ default: () => { } })
], InfosetExportProgressContainer.prototype, "infoset", void 0);
__decorate([
    Watch('exportationStatus')
], InfosetExportProgressContainer.prototype, "loadingCompleteStatus", null);
__decorate([
    Watch('waitingStatus')
], InfosetExportProgressContainer.prototype, "onWaitingStatusChange", null);
__decorate([
    Watch('currentTab')
], InfosetExportProgressContainer.prototype, "onTabChange", null);
InfosetExportProgressContainer = __decorate([
    Component(connect(({ actions }) => ({
        actions: actions.infosets,
    }), {
        components: {
            InfosetExportProgress,
        },
    }))
], InfosetExportProgressContainer);
export default InfosetExportProgressContainer;
