var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue, PropSync } from 'vue-property-decorator';
import Accordion from '@/components/Accordion.vue';
let ExportationsTypes = class ExportationsTypes extends Vue {
    constructor() {
        super(...arguments);
        this.open = true;
        this.exportOptions = [
            {
                label: 'Uma empresa por linha',
                value: 'companies',
            },
            {
                label: 'Um decisor por linha',
                value: 'decisors',
            },
            {
                label: 'Um e-mail por linha',
                value: 'emails',
            },
        ];
    }
};
__decorate([
    PropSync('currentType', { default: 'companies' })
], ExportationsTypes.prototype, "exportType", void 0);
ExportationsTypes = __decorate([
    Component({
        name: 'ExportationsTypes',
        components: {
            Accordion,
        },
    })
], ExportationsTypes);
export default ExportationsTypes;
