var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Prop, Vue, Component } from 'vue-property-decorator';
let Accordion = class Accordion extends Vue {
    constructor() {
        super(...arguments);
        this.opened = false;
    }
    mounted() {
        this.opened = this.open;
    }
};
__decorate([
    Prop({ default: () => { } })
], Accordion.prototype, "open", void 0);
Accordion = __decorate([
    Component({
        name: 'Accordion',
    })
], Accordion);
export default Accordion;
