var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue, Prop, Watch, Emit } from 'vue-property-decorator';
let Star = class Star extends Vue {
    constructor() {
        super(...arguments);
        this.stared = false;
    }
    onStar(newStar, oldStar) {
        this.stared = newStar === 'true';
    }
    sendToggleStar() {
        return !this.stared;
    }
    created() {
        if (this.star) {
            this.stared = this.star === 'true';
        }
    }
};
__decorate([
    Prop({ default: '' })
], Star.prototype, "star", void 0);
__decorate([
    Watch('star')
], Star.prototype, "onStar", null);
__decorate([
    Emit('toggleStar')
], Star.prototype, "sendToggleStar", null);
Star = __decorate([
    Component({
        name: 'Star',
    })
], Star);
export default Star;
