var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue, Prop } from 'vue-property-decorator';
import { connect } from '@/overmind';
import InnerLoading from '@/components/InnerLoading.vue';
import { isEmpty } from 'lodash';
let InfosetExportProgress = class InfosetExportProgress extends Vue {
    get loadingText() {
        if (this.currentTab === 'csv' || this.currentTab === 'excel')
            return 'A sua lista foi processada e está disponível para download';
        else
            return 'A sua lista foi processada e deve estar disponível na plataforma';
    }
    isExportationNotCompleted() {
        return isEmpty(this.exportationStatus.match('completed'));
    }
    async downloadFiles() {
        this.$emit('downloadFile', true);
    }
};
__decorate([
    Prop({ default: () => { } })
], InfosetExportProgress.prototype, "currentTab", void 0);
__decorate([
    Prop({ default: () => 0 })
], InfosetExportProgress.prototype, "percentage", void 0);
__decorate([
    Prop({ default: () => '' })
], InfosetExportProgress.prototype, "exportationStatus", void 0);
__decorate([
    Prop({ default: () => true })
], InfosetExportProgress.prototype, "waitingStatus", void 0);
__decorate([
    Prop({ default: false })
], InfosetExportProgress.prototype, "loadingBtn", void 0);
InfosetExportProgress = __decorate([
    Component(connect(({ actions }) => ({
        actions: actions.infosets,
    }), {
        components: {
            InnerLoading,
        },
    }))
], InfosetExportProgress);
export default InfosetExportProgress;
