var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue, Prop, PropSync, Watch } from 'vue-property-decorator';
import InfosetExportTabs from '@/modules/infosets/components/ExternalExportations/InfosetExportTabs.vue';
import InfosetExportContent from './InfosetExportContent.container.vue';
import InfosetExportProgressContainer from '@/modules/infosets/containers/Exportations/InfosetExportProgress.container.vue';
import Modal from 'components/Modal.vue';
import { exceedLimit } from '@/shared/utils/Infosets.utils';
import InnerLoading from '@/components/InnerLoading.vue';
import Tooltip from 'components/Tooltip.vue';
import { connect } from '@/overmind';
import { mixpanelTracking } from '@/services/mixpanel';
import { getApiExportBaseUrl } from '@/shared/utils/helpers';
let InfosetExportModalContainer = class InfosetExportModalContainer extends Vue {
    constructor() {
        super(...arguments);
        this.loadedExportationsInProgress = false;
        this.warningTotvsMsg = 'Atenção! Antes de exportar sua lista, verifique se a opção de atualizar dados das oportunidades está de acordo. Caso não precise atualizar os dados, então basta clicar em exportar. Se precisar atualizar, basta habilitar a opção de <b>Atualizar dados... </b> e em seguida clique em exportar.';
        this.buttonLoading = false;
        this.tab = '';
        this.thumbStyleScrool = {
            right: '2px',
            borderRadius: '5px',
            backgroundColor: '#CFD8DC',
            width: '5px',
            opacity: 0.75,
        };
        this.exportedCnpjForMeetime = 0;
        this.hasLoading = {};
        this.decisorsFilter = true;
        this.loadingWaitingComplete = false;
        this.loadingPercentageCompleted = false;
        // end
    }
    changeDecisorsFilter(value) {
        this.decisorsFilter = value;
    }
    handleExportationButton(type) {
        switch (type) {
            case 'color':
                return !this.loadingPercentageCompleted ? 'negative' : 'white';
            case 'label':
                return !this.loadingPercentageCompleted ? 'Cancelar' : 'Voltar';
        }
    }
    handleMsgExceedLimit(tab) {
        // const userLogged = localStorage.getItem('userEmailLogged') || ''
        // const isSankhyaOrSpeedioUser =
        //   !!userLogged.match('@sankhya') || !!userLogged.match('@speedio')
        // const noCompaniesToExportForMeetime =
        //   this.getCompaniesStats() - this.exportedCnpjForMeetime
        const handleLimit = tab == 'excel'
            ? '100.000 linhas'
            : tab == 'agendor'
                ? '1.500 registros'
                : '10.000 registros';
        // if (
        //   this.tab == 'meetime' &&
        //   isSankhyaOrSpeedioUser &&
        //   noCompaniesToExportForMeetime == 0
        // )
        //   return 'Não existem empresas disponíveis para exportação. Por favor, verifique se há empresas na lista ou se as empresas existentes já foram exportadas anteriormente.'
        return `A quantidade de empresas ou decisores excedeu o limite de ${handleLimit}. Que tal aumentar os critérios da sua busca?`;
    }
    loadingWaitingStatus(status) {
        this.loadingWaitingComplete = !status;
    }
    loadingCompleted(value) {
        this.loadingPercentageCompleted = value;
    }
    async mounted() {
        //tracking exportation is opened
        this.handleMixpanelTracking('exportação externa: Abriu exportação CRM');
        //@ts-ignore
        const accountId = await this.users.getAccountId();
        //@ts-ignore
        const result = await this.actions.getInfosetExportationsInProgress({
            accountId: accountId,
            infosetId: this.infoset.id,
        });
        if (result) {
            result.map(el => {
                const service = el.params.service.split('-')[0];
                this.hasLoading[service] = el._id;
            });
            this.loadedExportationsInProgress = true;
        }
    }
    async calculateExportedCnpjsForMeetime() {
        const result = await Vue.axios.get(`${getApiExportBaseUrl()}/export/${this.infoset.id}/calculate_exported_cnpjs_for_meetime`);
        this.exportedCnpjForMeetime = result.data.value;
    }
    disableExportButton() {
        return !!this.disableExportButtonMessage();
    }
    disableExportButtonMessage() {
        //@ts-ignore
        const companies = this.exportStats.companies;
        //@ts-ignore
        const decisors = this.exportStats.decisors;
        if (companies == 0) {
            return 'Não há empresas para exportação, revise os filtros aplicados';
        }
        const exceedLimitDecisorsOrCompanies = exceedLimit(this.tab, companies, decisors, this.decisorsFilter);
        if (exceedLimitDecisorsOrCompanies) {
            return this.hanldeLimitMessage();
        }
        if (this.buttonLoading) {
            return 'Carregando...';
        }
        // const companieToExportForMeetime =
        //   this.exportedCnpjForMeetime - this.getCompaniesStats()
        // const noCompaniesToExportForMeetime =
        //   this.getCompaniesStats() < 1 ||
        //   (this.tab == 'meetime' && companieToExportForMeetime == 0)
    }
    hanldeLimitMessage() {
        let limit = '';
        switch (this.tab) {
            case 'excel':
                limit = '100.000 linhas';
                break;
            case 'agendor':
                limit = '1.500 registros';
                break;
            default:
                limit = '10.000 registros';
        }
        return `A quantidade de empresas ou decisores excedeu o limite de ${limit}. Que tal aumentar os critérios da sua busca?`;
    }
    getCompaniesStats() {
        //@ts-ignore
        return this.exportStats.companies;
    }
    getComponentType() {
        if (this.tab == 'excel' || this.tab == 'csv')
            return 'sheet';
        else
            return 'crm';
    }
    async destroyed() {
        // @ts-ignore
        this.actions.setCurrentInfosetByID('');
    }
    handleClick() {
        if (!this.hasLoading[this.tab])
            this.exportClicked();
        else
            this.cancelExportation();
    }
    exportClicked() {
        this.handleMixpanelTracking('exportação externa: Iniciou uma exportação para CRM');
        //@ts-ignore
        this.$refs.exportContentRef.btnClicked();
    }
    cancelExportation() {
        this.handleMixpanelTracking('exportação externa: Cancelou uma exportação para CRM');
        //@ts-ignore
        this.$refs.loadingProgress.btnClicked();
    }
    removeTabFromQueue(tab) {
        this.hasLoading = JSON.parse(JSON.stringify(this.hasLoading));
        delete this.hasLoading[tab];
    }
    addToLoadingQueue(tab, _id) {
        this.hasLoading = JSON.parse(JSON.stringify(this.hasLoading));
        this.hasLoading[tab] = _id;
    }
    async trackinSelectedTabs() {
        this.handleMixpanelTracking(`exportação externa: Selecionou exportação para ${this.tab}`);
        const userLogged = localStorage.getItem('userEmailLogged') || '';
        const isSankhyaUser = !!userLogged.match('@sankhya') || !!userLogged.match('@speedio');
        if (this.tab == 'meetime' && isSankhyaUser) {
            await this.calculateExportedCnpjsForMeetime();
        }
    }
    trackingSuccessOnExportation() {
        this.loadingPercentageCompleted &&
            this.handleMixpanelTracking('exportação externa: Exportação para CRM foi bem sucedida');
    }
    // tracking
    handleMixpanelTracking(text) {
        return mixpanelTracking(text);
    }
};
__decorate([
    PropSync('open', { default: false })
], InfosetExportModalContainer.prototype, "showModal", void 0);
__decorate([
    Prop({ default: () => { } })
], InfosetExportModalContainer.prototype, "infoset", void 0);
__decorate([
    Watch('tab', { immediate: true })
], InfosetExportModalContainer.prototype, "trackinSelectedTabs", null);
__decorate([
    Watch('loadingPercentageCompleted')
], InfosetExportModalContainer.prototype, "trackingSuccessOnExportation", null);
InfosetExportModalContainer = __decorate([
    Component(connect(({ actions, state: { infosets: infosetsState } }) => ({
        actions: actions.infosets,
        users: actions.users,
        exportStats: infosetsState.exportStats,
    }), {
        name: 'InfosetExportModalContainer',
        components: {
            Modal,
            InfosetExportTabs,
            InfosetExportContent,
            InnerLoading,
            InfosetExportProgressContainer,
            Tooltip,
        },
    }))
], InfosetExportModalContainer);
export default InfosetExportModalContainer;
