var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"square-30 circle label small company-list-add-user",attrs:{"data-cy":"infoset-list-add-user-btn"},on:{"click":_vm.requestMembers}},[_vm._v("\n  +\n  "),_c('q-menu',{staticClass:"infoset-list-members-menu--dropdown",attrs:{"offset":[0, 10],"anchor":"bottom middle","self":"top middle","content-class":{ 'overflow-hidden': true }},on:{"hide":_vm.closeMenu,"show":_vm.showMenu}},[_c('q-list',{staticClass:"overflow-hidden list-infoset-members-menu-header",staticStyle:{"min-width":"400px","max-width":"400px"}},[_c('q-item',[_c('q-item-section',[_c('q-item-label',{staticClass:"q-pa-none text-body2 ellipsis"},[_vm._v(_vm._s(_vm.infosetName))]),_c('q-item-label',{attrs:{"caption":""}},[_vm._v("Adicionar / Remover usuários")])],1),_c('q-item-section',{attrs:{"side":"","top":""}},[_c('q-item-label',{attrs:{"caption":""}},[_c('span',{staticClass:"font-14",class:{ 'text-teal-5': _vm.limitMembersByTypeUser(_vm.countMembers) }},[_c('strong',[_c('span',{class:{ 'text-red-5': _vm.countMembers == 0 }},[_vm._v("\n                  "+_vm._s(_vm.countMembers)+"\n                ")]),_vm._v("\n                /\n                "+_vm._s(this.$auth.check([
                    'admin',
                    'owner',
                    'manager',
                    'customer_success' ])
                    ? 5
                    : 4)+"\n              ")])])])],1)],1),_c('q-item',{staticClass:"q-my-sm"},[_c('q-item-section',{staticClass:"input-filter-members-popover"},[_c('Input',{ref:"inputFilterMembersPopover",attrs:{"label":"Buscar usuário","iconClass":"icon-search","value":_vm.memberSearch},on:{"update:value":function($event){_vm.memberSearch=$event},"input":_vm.searchMembers}})],1)],1)],1),(_vm.members.length > 0)?_c('q-virtual-scroll',{staticClass:"list-members overflow-auto relative-position",staticStyle:{"min-height":"100px","max-height":"250px","padding-bottom":"20px"},attrs:{"items":_vm.members},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var item = ref.item;
                    var index = ref.index;
return [_c('q-inner-loading',{attrs:{"showing":_vm.loading}},[_c('q-spinner-dots',{staticClass:"q-mb-sm",attrs:{"color":"primary","size":"2.5em","thickness":3}}),_vm._v("\n          Carregando membros disponíveis...\n        ")],1),(!_vm.loading)?_c('q-item',{key:index,staticClass:"q-py-xs"},[_c('q-item-section',[_c('q-item-label',[_c('q-chip',{ref:"chipMembers",attrs:{"outlined":""},on:{"click":function($event){return _vm.showMember(item, index)}}},[_c('q-avatar',{attrs:{"text-color":"grey-7"}},[_c('q-icon',{attrs:{"size":"md","name":"account_circle"}})],1),_vm._v("\n                "+_vm._s(item.name)+"\n              ")],1)],1)],1),_c('q-item-section',{attrs:{"side":""}},[_c('div',{staticClass:"member"},[(_vm.notFullList(item))?_c('q-checkbox',{attrs:{"value":_vm.memberAdded(item.id)},on:{"input":function($event){return _vm.updateMembers(item, $event)}}}):_vm._e()],1)])],1):_vm._e()]}}],null,false,1337335135)}):_vm._e(),(_vm.availableMembers.length === 0)?_c('div',{staticClass:"row justify-center items-center q-pa-md"},[_c('p',{attrs:{"align":"center"}},[_vm._v("\n        Não existem usuários disponíveis do tipo "),_c('br'),_c('b',[_vm._v("Coordenador")]),_vm._v(" ou "),_c('b',[_vm._v("Prospector")])])]):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }