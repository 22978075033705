import { render, staticRenderFns } from "./Infosets.vue?vue&type=template&id=242479b3&scoped=true&"
import script from "./Infosets.vue?vue&type=script&lang=ts&"
export * from "./Infosets.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "242479b3",
  null
  
)

export default component.exports