var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue } from 'vue-property-decorator';
import InfosetsExportations from '@/modules/infosets/components/InfosetsExportations.vue';
import { connect } from '@/overmind';
let InfosetsExportationsContainer = class InfosetsExportationsContainer extends Vue {
    constructor() {
        super(...arguments);
        this.idInterval = 0;
    }
    async mounted() {
        // @ts-ignore
        await this.actions.getInfosetsExportEvents();
        this.idInterval = window.setInterval(async () => {
            // @ts-ignore
            await this.actions.getInfosetsExportEvents();
        }, 30000);
    }
    beforeDestroy() {
        clearInterval(this.idInterval);
    }
    async cancelExportation(eventId) {
        // @ts-ignore
        const success = await this.actions.cancelExportation(eventId);
        if (success) {
            this.$q.notify({
                message: 'Exportação cancelada!',
                type: 'success',
            });
        }
        else {
            this.$q.notify({
                message: 'Não foi possível cancelar esta exportação!',
                type: 'error',
            });
        }
    }
};
InfosetsExportationsContainer = __decorate([
    Component(connect(({ state, actions }) => ({
        state: state.infosets,
        actions: actions.infosets,
    }), { components: { InfosetsExportations } }))
], InfosetsExportationsContainer);
export default InfosetsExportationsContainer;
