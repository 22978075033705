var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue, Prop } from 'vue-property-decorator';
import InfosetsOverview from '../components/InfosetsOverview.vue';
import { connect } from '@/overmind';
let InfosetListOverview = class InfosetListOverview extends Vue {
    constructor() {
        super(...arguments);
        this.loadingOverview = false;
    }
    async overviewByMember(userId) {
        this.loadingOverview = true;
        // @ts-ignore TS2349: property inexistent
        const infosetId = this.state.currentInfoset.id;
        if (infosetId) {
            // @ts-ignore TS2349: property inexistent
            await this.actions.getInfosetsOverview({ infosetId, userId });
        }
        this.loadingOverview = false;
    }
    async mounted() {
        // @ts-ignore TS2349: property inexistent
        let infosetId = this.state.currentInfoset
            ? // @ts-ignore TS2349: property inexistent
                this.state.currentInfoset.id
            : null;
        // @ts-ignore TS2349: property inexistent
        let userId = this.$auth.user() ? this.$auth.user().id : null;
        if (infosetId && userId) {
            // @ts-ignore TS2349: property inexistent
            await this.actions.getInfosetsOverview({ infosetId, userId });
        }
    }
};
__decorate([
    Prop({ default: '' })
], InfosetListOverview.prototype, "infosetId", void 0);
__decorate([
    Prop({ default: false })
], InfosetListOverview.prototype, "loadingOverviewFromIfosetContainer", void 0);
InfosetListOverview = __decorate([
    Component(connect(({ state, effects, actions }) => ({
        state: state.infosets,
        actions: actions.infosets,
    }), {
        name: 'InfosetListOverview',
        components: { InfosetsOverview },
    }))
], InfosetListOverview);
export default InfosetListOverview;
