var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue } from 'vue-property-decorator';
import { json } from 'overmind';
import { connect } from '@/overmind';
import { InfosetsListStatus } from '@/overmind/infosets/state';
import { isEmpty } from 'lodash';
import SurveyModalPopUpContainer from '@/components/Survey/SurveyModalPopUp.container.vue';
import InfosetsHeader from '@/modules/infosets/components/InfosetsHeader.vue';
import EmptyMessage from '@/components/EmptyMessage.vue';
import InfosetsTable from '@/modules/infosets/components/InfosetsTable.vue';
import InfosetsTableSkeleton from '@/modules/infosets/components/InfosetsTableSkeleton.vue';
import InfosetsOverviewContainer from '@/modules/infosets/containers/InfosetListOverview.container.vue';
import InfosetsExportationsContainer from '@/modules/infosets/containers/InfosetsExportations.container.vue';
import Pagination from '@/components/Pagination.vue';
import OnboardingContainer from '@/components/Onboarding/Onboarding.container.vue';
import { mixpanelTracking } from '@/services/mixpanel';
import ContactAndSalesInfoModal from '@/components/ContactAndSalesInfoModal/ContactAndSalesInfoModal.vue';
import ReactivationAndReengagementModal from '@/components/ReactivationAndReengagementModal/ReactivationAndReengagementModal.vue';
let InfosetListContainer = class InfosetListContainer extends Vue {
    constructor() {
        super(...arguments);
        this.currentPage = 1;
        this.searchTerm = '';
        this.loadingToExportCsv = false;
        this.SendingDataPipeDriveSuccess = false;
        this.SendingDataPloomesSuccess = false;
        this.loadingGetOverview = false;
        this.infosetIdByOverview = '';
        this.infosetsTab = 'lists';
        this.infosetsViablesToShowSpeedmailBtn = {};
        this.loadingViable = false;
        this.searchInfosetNow = false;
    }
    creditsPercentage() {
        return {
            //@ts-ignore
            clicks: this.accountCredits.clicks.percentage,
            //@ts-ignore
            export: this.accountCredits.export.percentage,
        };
    }
    async exportPipedrive(data) {
        // @ts-ignore TS2349: property inexistent
        let token = await localStorage.getItem('tokenPipeDrive');
        this.SendingDataPipeDriveSuccess = true;
        // @ts-ignore TS2349: property inexistent
        await this.actionsUsers.exportPipedrive({ token, data });
        setTimeout(() => {
            this.SendingDataPipeDriveSuccess = false;
        }, 1000);
    }
    handleMixpanelTabTracking(text) {
        mixpanelTracking('minhas listas: Clicou na aba ' + text);
    }
    emptyMessage() {
        if (this.fetchError()) {
            return 'Houve um problema para carregar suas listas!';
        }
        else if (this.searchTerm) {
            return 'Sua busca não retornou nenhuma <b>lista</b>!';
        }
        else {
            return 'Sua <b>lista</b> está vazia!';
        }
    }
    async starInfoset(infosetStar) {
        // @ts-ignore TS2349: property inexistent
        await this.actions.updateStarToInfoset(infosetStar);
    }
    async infosetsList(pageNumber, searchTerm = '') {
        // @ts-ignore TS2349: property inexistent
        await this.actions.fetchAllInfosets({
            page: pageNumber,
            query: searchTerm,
        });
    }
    async changePage(pageNumber) {
        // @ts-ignore TS2349: property inexistent
        if (this.page !== pageNumber) {
            this.currentPage = pageNumber;
            // @ts-ignore TS2349: property inexistent
            this.actions.setPage(pageNumber);
            await this.infosetsList(pageNumber, this.searchTerm);
            await this.getPermissionToShowSpeedmailBtn();
        }
    }
    changeSearchInfosetNow(searchT) {
        this.searchInfosetNow = false;
    }
    async onEditInfoset(params) {
        // @ts-ignore TS2349: property inexistent
        const result = await this.actions.editInfoset({
            infosetId: params.infosetId,
            payload: params.newValues,
        });
        if (result) {
            mixpanelTracking('minhas listas: Lista editada');
            this.$q.notify({
                message: 'Lista editada com sucesso!',
                type: 'success',
            });
        }
        else
            this.$q.notify({
                message: 'Aconteceu um erro inesperado tente novamente mais tarde!',
                type: 'error',
            });
    }
    async showStatsInfoset(infosetId) {
        this.loadingGetOverview = true;
        await this.setCurrentInfoset(infosetId);
        // @ts-ignore TS2349: property inexistent
        let userId = this.$auth.user().id;
        // @ts-ignore TS2349: property inexistent
        this.infosetIdByOverview = infosetId;
        // @ts-ignore TS2349: property inexistent
        await this.actions.getInfosetsOverview({ infosetId, userId });
        this.loadingGetOverview = false;
        mixpanelTracking('minhas listas: Acessou as estatisticas da lista');
    }
    async onRecoverInfoset(infosetId) {
        // @ts-ignore TS2349: property inexistent
        const result = await this.actions.recoverInfoset(infosetId);
        if (result.status === 201) {
            if (this.searchTerm) {
                this.searchInfosets(this.searchTerm);
            }
            else {
                this.initData();
            }
            mixpanelTracking('minhas listas: Lista recuperada');
            this.$q.notify({
                message: 'Lista recuperada com sucesso!',
                type: 'success',
            });
        }
        else
            this.$q.notify({
                message: 'Aconteceu um erro inesperado tente novamente mais tarde!',
                type: 'error',
            });
    }
    async onRemoveInfoset(infosetId) {
        // @ts-ignore TS2349: property inexistent
        const result = await this.actions.deleteInfoset(infosetId);
        if (result === 200 || result === 201) {
            if (this.searchTerm) {
                this.searchInfosets(this.searchTerm);
            }
            else {
                this.initData();
            }
            mixpanelTracking('minhas listas: Lista deletada');
            this.$q.notify({
                message: 'Lista deletada com sucesso!',
                type: 'success',
            });
        }
        else
            this.$q.notify({
                message: 'Aconteceu um erro inesperado tente novamente mais tarde!',
                type: 'error',
            });
    }
    async getAllUsers() {
        // @ts-ignore TS2349: property inexistent
        await this.actions.getAllMembersAvailable();
    }
    async create(infosetName) {
        if (typeof infosetName === 'string') {
            const newInfoset = {
                name: infosetName,
                contacts: 0,
                members: [],
                star: 'false',
                emailsValidationStatus: 'not initialized',
                phonesValidationStatus: 'not initialized',
            };
            //this.initData()
            // @ts-ignore TS2349: property inexistent
            const result = await this.actions.createInfoset(newInfoset);
            if (result) {
                mixpanelTracking('minhas listas: Lista criada');
                this.$q.notify({
                    message: 'Lista criada com sucesso!',
                    type: 'success',
                });
            }
            else
                this.$q.notify({
                    message: 'Aconteceu um erro inesperado tente novamente mais tarde!',
                    type: 'error',
                });
        }
    }
    async searchInfosets(term) {
        const page = 1;
        this.searchTerm = term;
        // @ts-ignore TS2349: property inexistent
        this.actions.setPage(page);
        await this.infosetsList(page, term);
        await this.getPermissionToShowSpeedmailBtn();
        mixpanelTracking('minhas listas: Pesquisou por uma lista');
    }
    async setCurrentInfoset(infosetId) {
        mixpanelTracking('minhas listas: Abriu uma lista');
        // @ts-ignore TS2349: property inexistent
        await this.actions.setCurrentInfosetByID(infosetId);
    }
    handleInfosets() {
        // @ts-ignore TS2349: property inexistent
        const infosets = json(this.infosets);
        return infosets.map(infoset => {
            const validating = false;
            /* const validating = !isEmpty(this.infosetsViablesToShowSpeedmailBtn)
              ? this.infosetsViablesToShowSpeedmailBtn[infoset.id]
              : false
          */
            return {
                author: infoset.author,
                name: infoset.name,
                createdAt: infoset.createdAt,
                contacts: infoset.contacts,
                emailsValidationStatus: infoset.emailsValidationStatus,
                id: infoset.id,
                members: infoset.members,
                phonesValidationStatus: infoset.phonesValidationStatus,
                search_ids: infoset.search_ids,
                star: infoset.star,
                updatedAt: infoset.updatedAt,
                deletedAt: infoset.deletedAt,
                showSpeedmailBtn: validating,
            };
        });
    }
    fetching() {
        return (this.loadingViable ||
            // @ts-ignore TS2349: property inexistent
            this.infosetsListStatus === InfosetsListStatus.FETCHING);
    }
    async mounted() {
        this.$gtag.pageview({ page_path: '/' });
        await this.initData();
        //@ts-ignore
        await this.getPermissionToShowSpeedmailBtn();
        mixpanelTracking('minhas listas: Acessou página de minhas listas');
    }
    async getPermissionToShowSpeedmailBtn() {
        this.loadingViable = true;
        //@ts-ignore
        const data = json(this.infosets).map(infoset => {
            return {
                infoset: infoset.id,
                validating: infoset.emailsValidationStatus !== 'finished',
            };
        });
        this.loadingViable = false;
        return;
    }
    async initData() {
        // @ts-ignore TS2349: property inexistent
        await this.actions.fetchAllInfosets({ limit: this.limit, page: this.page });
    }
    get fetchSuccess() {
        return (
        // @ts-ignore TS2349: property inexistent
        this.infosetsListStatus === InfosetsListStatus.INITIAL ||
            // @ts-ignore TS2349: property inexistent
            this.infosetsListStatus === InfosetsListStatus.FETCH_SUCCESS);
    }
    isAvailableInfosets() {
        // @ts-ignore TS2349: property inexistent
        return this.fetchSuccess && !isEmpty(this.infosets);
    }
    fetchError() {
        // @ts-ignore TS2349: property inexistent
        return this.infosetsListStatus === InfosetsListStatus.FETCH_ERROR;
    }
};
InfosetListContainer = __decorate([
    Component(connect(({ state: { infosets: state, companies: companiesState, users: usersState }, actions, }) => ({
        infosets: state.infosetsList,
        infosetsListStatus: state.infosetsListStatus,
        totalPages: state.totalPages,
        page: state.page,
        membersAvailable: state.membersAvailable,
        companies: companiesState.companies,
        status: state.status,
        actions: actions.infosets,
        dataDecisors: state.companiesByDecisors,
        dataEmailsCsv: state.companiesByEmails,
        pipeDriveKey: usersState.tokenPipeDrive,
        actionsUsers: actions.users,
        currentUser: usersState.currentUser,
        objectKeyContactPloomes: usersState.objectKeyContactPloomes,
        accountCredits: usersState.accountCredits,
        actionsSearch: actions.search,
    }), {
        name: 'InfosetListContainer',
        components: {
            InfosetsHeader,
            InfosetsTable,
            InfosetsOverviewContainer,
            Pagination,
            InfosetsTableSkeleton,
            EmptyMessage,
            SurveyModalPopUpContainer,
            InfosetsExportationsContainer,
            OnboardingContainer,
            ContactAndSalesInfoModal,
            ReactivationAndReengagementModal,
        },
    }))
], InfosetListContainer);
export default InfosetListContainer;
