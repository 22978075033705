var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue, PropSync, Prop } from 'vue-property-decorator';
import Accordion from '@/components/Accordion.vue';
let ExportationsLeadStatus = class ExportationsLeadStatus extends Vue {
    constructor() {
        super(...arguments);
        this.exportOptionSelected = 'Open - Not Contacted';
        this.exportOptions = [
            {
                label: 'Não contatato',
                value: 'Open - Not Contacted',
            },
            {
                label: 'Contatado',
                value: 'Working - Contacted',
            },
            {
                label: 'Convertido',
                value: 'Closed - Converted',
            },
            {
                label: 'Não convertido',
                value: 'Closed - Not Converted',
            },
        ];
    }
    mounted() {
        /*TODO o componente ExportationsFields, precisa ser carregado antes desse componente,
               foi criado um setTimeout para tornar isso possivel... Verificar melhor maneira
               para resolver esse problema.
        */
        this.exportOptionSelected =
            this.currentFields['Status'] || this.exportOptionSelected;
        setTimeout(() => {
            this.$emit('updateLeads', this.exportOptionSelected);
        }, 1000);
    }
    async changeExportType() {
        this.$emit('updateLeads', this.exportOptionSelected);
    }
    beforeDestroy() {
        this.$emit('updateLeads', null);
    }
};
__decorate([
    PropSync('currentTab', { default: 'summary' })
], ExportationsLeadStatus.prototype, "tab", void 0);
__decorate([
    PropSync('currentService', { default: 'summary' })
], ExportationsLeadStatus.prototype, "service", void 0);
__decorate([
    PropSync('currentFilters', { default: () => { } })
], ExportationsLeadStatus.prototype, "filters", void 0);
__decorate([
    Prop({ default: () => { } })
], ExportationsLeadStatus.prototype, "open", void 0);
__decorate([
    Prop({ default: () => { } })
], ExportationsLeadStatus.prototype, "currentFields", void 0);
ExportationsLeadStatus = __decorate([
    Component({
        name: 'ExportationsLeadStatus',
        components: {
            Accordion,
        },
    })
], ExportationsLeadStatus);
export default ExportationsLeadStatus;
