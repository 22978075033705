var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue, Prop } from 'vue-property-decorator';
import Header from './InfosetExportHeader.vue';
import { getVideosCRM } from '@/shared/utils/Infosets.utils';
import { connect } from '@/overmind';
import IconCompanies from '@/components/Autoplay/icons/IconCompanies.vue';
import IconMakeDecisors from '@/components/Autoplay/icons/IconMakeDecisors.vue';
let InfosetExportHeader = class InfosetExportHeader extends Vue {
    constructor() {
        super(...arguments);
        this.showFrame = false;
        this.urlFrame = '';
    }
    get linkVideo() {
        return getVideosCRM(this.currentTab);
    }
    capitalizeName(name) {
        return name.charAt(0).toUpperCase() + name.slice(1);
    }
    handleCurrentTab() {
        switch (this.currentTab) {
            case 'totvs':
                return 'TOTVS CRM';
            case 'csv':
                return 'CSV';
            case 'funildevendas':
                return 'Funil de Vendas';
            case 'rdstation':
                return 'RD Station';
            case 'zoho':
                return 'Zoho CRM';
            default:
                return this.capitalizeName(this.currentTab);
        }
    }
    exportationStatus(arg) {
        switch (arg) {
            case 'companies':
                //@ts-ignore
                return this.exportStats.companies;
            case 'decisors':
                //@ts-ignore
                return this.exportStats.decisors;
        }
        return '0';
    }
    decisors() {
        return this.exportationStatus('decisors');
    }
    companies() {
        return this.exportationStatus('companies');
    }
    handleIframe(code) {
        if (code) {
            this.urlFrame = `https://www.youtube.com/embed/${code}`;
            this.showFrame = true;
            return;
        }
        this.showFrame = false;
    }
    get showDecisors() {
        return ['excel', 'csv'].includes(this.currentTab) || this.withDecisors;
    }
};
__decorate([
    Prop({ default: () => { } })
], InfosetExportHeader.prototype, "currentTab", void 0);
__decorate([
    Prop({ default: () => true })
], InfosetExportHeader.prototype, "withDecisors", void 0);
InfosetExportHeader = __decorate([
    Component(connect(({ state: { infosets: infosetsState } }) => ({
        exportStats: infosetsState.exportStats,
    }), {
        name: 'InfosetExportHeader',
        components: {
            Header,
            IconCompanies,
            IconMakeDecisors,
        },
    }))
], InfosetExportHeader);
export default InfosetExportHeader;
