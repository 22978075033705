var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
let InfosetsMemberActionsMenu = class InfosetsMemberActionsMenu extends Vue {
    hideActions() {
        return true;
    }
    get memberName() {
        if (this.member && this.member.name) {
            return this.member.name
                .trim()
                .split(' ')
                .map(memberName => {
                if (memberName && memberName[0]) {
                    return memberName[0].toUpperCase() + memberName.substring(1);
                }
                return '';
            })
                .join(' ');
        }
        return '';
    }
    get handleAuthorLabel() {
        if (this.isAuthor)
            return '(Autor)';
        return '';
    }
    goToInfosetAsMember() {
        if (this.member.id === this.currentUser.id) {
            this.$router.push({
                name: 'infoset',
                params: { infosetId: this.infosetId },
            });
        }
        else {
            this.$router.push({
                name: 'infoset',
                params: { infosetId: this.infosetId },
                query: { view_as: this.member.id },
            });
        }
    }
    removeMember(member) {
        return this.member;
    }
};
__decorate([
    Prop({ default: () => { } })
], InfosetsMemberActionsMenu.prototype, "member", void 0);
__decorate([
    Prop({ default: '' })
], InfosetsMemberActionsMenu.prototype, "infosetId", void 0);
__decorate([
    Prop({ default: false })
], InfosetsMemberActionsMenu.prototype, "isAuthor", void 0);
__decorate([
    Prop({ default: () => { } })
], InfosetsMemberActionsMenu.prototype, "currentUser", void 0);
__decorate([
    Emit('hideActions')
], InfosetsMemberActionsMenu.prototype, "hideActions", null);
__decorate([
    Emit('removeMember')
], InfosetsMemberActionsMenu.prototype, "removeMember", null);
InfosetsMemberActionsMenu = __decorate([
    Component
], InfosetsMemberActionsMenu);
export default InfosetsMemberActionsMenu;
