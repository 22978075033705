var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Vue, Emit, Component, Prop } from 'vue-property-decorator';
import IconAddList from '@/components/Icons/iconEye.vue';
let InfosetsDropdownMenu = class InfosetsDropdownMenu extends Vue {
    showEditModal() {
        return true;
    }
    showExportModal() {
        return true;
    }
    showRemoveModal() {
        return true;
    }
    recoverInfoSet() {
        return this.infosetId;
    }
    showInfosetOverview() { }
    openSearchesClick() {
        return true;
    }
    copyInfosetLink() {
        const url = `${window.location.origin}/infosets/${this.infosetId}`;
        navigator.clipboard.writeText(url);
    }
    get adminOrCs() {
        // @ts-ignore
        return this.$auth.check('admin') || this.$auth.check('customer_success');
    }
    openAccuracyDataFeedback() { }
};
__decorate([
    Prop({ default: () => [] })
], InfosetsDropdownMenu.prototype, "searches", void 0);
__decorate([
    Prop({ default: () => [] })
], InfosetsDropdownMenu.prototype, "infosetId", void 0);
__decorate([
    Prop({ default: () => false })
], InfosetsDropdownMenu.prototype, "deleted", void 0);
__decorate([
    Emit('editClick')
], InfosetsDropdownMenu.prototype, "showEditModal", null);
__decorate([
    Emit('exportClick')
], InfosetsDropdownMenu.prototype, "showExportModal", null);
__decorate([
    Emit('removeClick')
], InfosetsDropdownMenu.prototype, "showRemoveModal", null);
__decorate([
    Emit('recoverClick')
], InfosetsDropdownMenu.prototype, "recoverInfoSet", null);
__decorate([
    Emit('statsClick')
], InfosetsDropdownMenu.prototype, "showInfosetOverview", null);
__decorate([
    Emit('openSearchesClick')
], InfosetsDropdownMenu.prototype, "openSearchesClick", null);
__decorate([
    Emit('openAccuracyDataFeedback')
], InfosetsDropdownMenu.prototype, "openAccuracyDataFeedback", null);
InfosetsDropdownMenu = __decorate([
    Component({
        name: 'InfosetsTableRow',
        components: {
            IconAddList,
        },
    })
], InfosetsDropdownMenu);
export default InfosetsDropdownMenu;
