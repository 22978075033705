var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Prop, Vue } from 'vue-property-decorator';
import InfosetsMemberActionsMenu from '@/modules/infosets/components/InfosetsMemberActionsMenu.vue';
import { connect } from '@/overmind';
let InfosetsMemberActionsMenuContainer = class InfosetsMemberActionsMenuContainer extends Vue {
    async removeMember(member) {
        // @ts-ignore TS2349: property inexistent
        this.removeMemberFromInfoset({
            infosetId: this.infosetId,
            member: member,
        });
    }
};
__decorate([
    Prop({ default: () => { } })
], InfosetsMemberActionsMenuContainer.prototype, "member", void 0);
__decorate([
    Prop({ default: '' })
], InfosetsMemberActionsMenuContainer.prototype, "infosetId", void 0);
__decorate([
    Prop({ default: false })
], InfosetsMemberActionsMenuContainer.prototype, "isAuthor", void 0);
InfosetsMemberActionsMenuContainer = __decorate([
    Component(connect(({ state: { infosets: state, users: usersState }, actions }) => ({
        membersAvailable: state.membersAvailable,
        filteredMembersAvailable: state.filteredMembersAvailable,
        currentInfoset: state.currentInfoset,
        currentUser: usersState.currentUser,
        getAllAvailable: actions.infosets.getAllMembersAvailable,
        setCurrentInfosetByID: actions.infosets.setCurrentInfosetByID,
        addMemberToInfoset: actions.infosets.addMemberToInfoset,
        removeMemberFromInfoset: actions.infosets.removeMemberFromInfoset,
        setSearchMemberTerm: actions.infosets.setSearchMemberTerm,
        setOrderToMembersAvailable: actions.infosets.setOrderToMembersAvailable,
    }), {
        components: {
            InfosetsMemberActionsMenu,
        },
    }))
], InfosetsMemberActionsMenuContainer);
export default InfosetsMemberActionsMenuContainer;
