var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue, Emit } from 'vue-property-decorator';
import Input from 'components/Input.vue';
import iconGift from '@/components/Icons/IconGift.vue';
let InfosetsHeader = class InfosetsHeader extends Vue {
    constructor() {
        super(...arguments);
        this.infosetName = '';
        this.searchTerm = '';
        this.hasError = false;
        this.errorMessage = '';
    }
    async saveInfoset() {
        if (!this.hasError) {
            if (this.infosetName !== '') {
                // @ts-ignore TS2349: property inexistent
                if (!this.$refs.name.$refs.qinput.innerError) {
                    const name = this.infosetName.trim();
                    // @ts-ignore TS2349: property inexistent
                    this.$refs.btnSaveDropdown.hide();
                    this.infosetName = '';
                    this.errorMessage = '';
                    this.hasError = false;
                    return name;
                }
            }
            else {
                this.errorMessage = 'Nome não pode ficar em branco';
                this.hasError = true;
            }
        }
    }
    hasNoSpecialChars() {
        if (this.infosetName === '' || this.infosetName === null) {
            this.hasError = false;
            return;
        }
        const isValid = !!(this.infosetName !== null &&
            this.infosetName.replace(/[^\w\s]|_/gi, '').trim() !== '');
        if (!isValid) {
            this.hasError = true;
            return 'Forneça um nome válido';
        }
        else {
            this.hasError = false;
            return;
        }
    }
    // verifySearchInfosetNow() {
    //   if (this.searchInfosetNow) {
    //     this.searchInfosets(this.searchTerm)
    //   }
    // }
    searchInfosets(term) {
        return term;
    }
    get actionPermitted() {
        // @ts-ignore
        return this.$auth.check([
            'admin',
            'owner',
            'manager',
            'coordinator',
            'customer_success',
        ]);
    }
};
__decorate([
    Emit('createInfoset')
], InfosetsHeader.prototype, "saveInfoset", null);
__decorate([
    Emit('searchInfosets')
], InfosetsHeader.prototype, "searchInfosets", null);
InfosetsHeader = __decorate([
    Component({
        name: 'InfosetsHeader',
        components: {
            Input,
            iconGift,
        },
    })
], InfosetsHeader);
export default InfosetsHeader;
