var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import Accordion from '@/components/Accordion.vue';
import Checkbox from '@/components/Checkbox.vue';
import { getfieldFilters } from '@/shared/utils/Infosets.utils';
let ExportationsFields = class ExportationsFields extends Vue {
    constructor() {
        super(...arguments);
        this.checkbox = true;
        this.fields = [];
        this.search = '';
    }
    get filterListItems() {
        return this.fields.filter(el => {
            const name = el.name.toLowerCase();
            const matchSearch = name.match(this.search.toLowerCase().replaceAll('-', ''));
            const notDecisor = name != 'decisores';
            return matchSearch && notDecisor;
        });
    }
    changedCurrentTab() {
        this.refreshListItems();
        this.$emit('updateFields', this.fields);
    }
    refreshListItems() {
        const items = getfieldFilters(this.documentType === 'sheet' ? 'sheet' : this.currentTab);
        this.fields = this.listItemsRearrange(items);
        this.checkbox = true;
        this.initializeFields();
    }
    mounted() {
        this.refreshListItems();
        const populateFields = Object.entries(this.currentFields);
        populateFields.forEach(el => {
            const index = this.fields.findIndex(res => res.name == el[0]);
            if (index >= 0)
                this.fields[index].checkbox = el[1];
        });
        this.handleToggleButton();
        this.$emit('updateFields', this.fields);
    }
    isDisabled(element) {
        return [
            'Razão Social',
            'Razão Social/Nome Fantasia',
            'Nome/Razão Social',
            'Apelido/Nome Fantasia',
            'CNPJ',
        ].includes(element);
    }
    listItemsRearrange(list) {
        if (list) {
            const newArr = list.map(el => ({
                name: el,
                checkbox: this.isDisabled(el) ? true : false,
            }));
            return newArr;
        }
        return [];
    }
    initializeFields() {
        this.fields.forEach(el => {
            el.checkbox =
                el.checkbox == this.isDisabled(el.name) ? true : this.checkbox;
        });
        this.$emit('updateFields', this.fields);
    }
    getSelected() {
        const newArr = this.fields;
        const filter = newArr.filter(el => el.checkbox);
        return filter.length;
    }
    handleToggleButton() {
        if (this.fields.every(el => el.checkbox === true)) {
            this.checkbox = true;
            return;
        }
        else {
            this.checkbox = false;
            return;
        }
    }
    onChange() {
        this.handleToggleButton();
        this.$emit('updateFields', this.fields);
    }
    formatLabel(label) {
        switch (label) {
            case 'Emails':
                return 'E-mails';
            case 'Todos os Emails':
                return 'Todos os E-mails';
            case 'Emails Válidos':
                return 'E-mails Válidos';
            case 'Emails Incertos':
                return 'E-mails Incertos';
            case 'Emails de Contador':
                return 'E-mails de Contador';
            case 'Email do tomador de decisão':
                return 'E-mail do tomador de decisão';
            case 'Emails Válidos de Decisores':
                return 'E-mails Válidos de Decisores';
            case 'Emails Principais':
                return 'E-mails Principais';
            default:
                return label;
        }
    }
};
__decorate([
    Prop({ default: () => { } })
], ExportationsFields.prototype, "documentType", void 0);
__decorate([
    Prop({ default: () => { } })
], ExportationsFields.prototype, "currentTab", void 0);
__decorate([
    Prop({ default: () => { } })
], ExportationsFields.prototype, "currentFields", void 0);
__decorate([
    Watch('currentTab')
], ExportationsFields.prototype, "changedCurrentTab", null);
ExportationsFields = __decorate([
    Component({
        name: 'ExportationsFields',
        components: { Checkbox, Accordion },
    })
], ExportationsFields);
export default ExportationsFields;
