var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Prop, Vue } from 'vue-property-decorator';
import InfosetsMoreListMembersMenu from '@/modules/infosets/components/InfosetsMoreListMembersMenu.vue';
import { connect } from '@/overmind';
let InfosetsMoreListMembersMenuContainer = class InfosetsMoreListMembersMenuContainer extends Vue {
    async removeMember(member) {
        // @ts-ignore TS2349: property inexistent
        this.removeMemberFromInfoset({
            infosetId: this.infosetId,
            member: member,
        });
    }
};
__decorate([
    Prop({ default: () => [] })
], InfosetsMoreListMembersMenuContainer.prototype, "moreMembers", void 0);
__decorate([
    Prop({ default: '' })
], InfosetsMoreListMembersMenuContainer.prototype, "infosetId", void 0);
InfosetsMoreListMembersMenuContainer = __decorate([
    Component(connect(({ actions }) => ({
        removeMemberFromInfoset: actions.infosets.removeMemberFromInfoset,
    }), {
        components: {
            InfosetsMoreListMembersMenu,
        },
    }))
], InfosetsMoreListMembersMenuContainer);
export default InfosetsMoreListMembersMenuContainer;
