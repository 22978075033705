var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue, PropSync, Prop } from 'vue-property-decorator';
import Modal from 'components/Modal.vue';
let InfosetRemoveModal = class InfosetRemoveModal extends Vue {
    confirmRemove() {
        this.$emit('onRemove');
    }
};
__decorate([
    PropSync('open', { default: false })
], InfosetRemoveModal.prototype, "showModal", void 0);
__decorate([
    Prop({ default: () => { } })
], InfosetRemoveModal.prototype, "infoset", void 0);
InfosetRemoveModal = __decorate([
    Component({
        name: 'InfosetRemoveModal',
        components: {
            Modal,
        },
    })
], InfosetRemoveModal);
export default InfosetRemoveModal;
