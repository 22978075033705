var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue, PropSync, Watch } from 'vue-property-decorator';
import Accordion from '@/components/Accordion.vue';
import Checkbox from '@/components/Checkbox.vue';
import { mixpanelTracking } from '@/services/mixpanel';
let ExportationsStatus = class ExportationsStatus extends Vue {
    constructor() {
        super(...arguments);
        this.checkbox = false;
        this.open = false;
    }
    updateCompanyStatus(value) {
        if (value)
            mixpanelTracking('exportação externa: Selecionou status de sucesso no pré-CRM');
        this.filters.companiesSuccessStatus = this.checkbox;
    }
    mounted() {
        this.checkbox = this.filters.companiesSuccessStatus;
    }
};
__decorate([
    PropSync('currentFilters', { default: () => { } })
], ExportationsStatus.prototype, "filters", void 0);
__decorate([
    PropSync('currentTab', { default: 'summary' })
], ExportationsStatus.prototype, "tab", void 0);
__decorate([
    Watch('checkbox')
], ExportationsStatus.prototype, "updateCompanyStatus", null);
ExportationsStatus = __decorate([
    Component({
        name: 'ExportationsStatus',
        components: {
            Accordion,
            Checkbox,
        },
    })
], ExportationsStatus);
export default ExportationsStatus;
