var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue, Prop } from 'vue-property-decorator';
import { connect } from '@/overmind';
import InfosetMembersMenu from '@/modules/infosets/components/InfosetsMembersMenu.vue';
import { isEmpty } from 'lodash';
let InfosetsMemberMenuContainer = class InfosetsMemberMenuContainer extends Vue {
    constructor() {
        super(...arguments);
        this.infosetMembers = [];
    }
    updateMembers({ member, event, }) {
        if (event) {
            // @ts-ignore TS2349: property inexistent
            this.addMemberToInfoset({
                infosetId: this.infosetId,
                member: member,
            });
        }
        else {
            // @ts-ignore TS2349: property inexistent
            this.removeMemberFromInfoset({
                infosetId: this.infosetId,
                member: member,
            });
        }
        this.$emit('changeMembers', true);
    }
    getInfosetMembers(currentInfoset) {
        if (!isEmpty(currentInfoset)) {
            return currentInfoset.members || [];
        }
        return [];
    }
    getInfosetName(currentInfoset) {
        if (!isEmpty(currentInfoset)) {
            return currentInfoset.name ? currentInfoset.name : '';
        }
        return '';
    }
    onShowMenu() {
        // @ts-ignore TS2349: property inexistent
        this.setCurrentInfosetByID(this.infosetId);
        // @ts-ignore TS2349: property inexistent
        this.setOrderToMembersAvailable();
    }
};
__decorate([
    Prop({ default: '' })
], InfosetsMemberMenuContainer.prototype, "infosetId", void 0);
InfosetsMemberMenuContainer = __decorate([
    Component(connect(({ state: { infosets: state }, actions }) => ({
        membersAvailable: state.membersAvailable,
        filteredMembersAvailable: state.filteredMembersAvailable,
        currentInfoset: state.currentInfoset,
        getAllAvailable: actions.infosets.getAllMembersAvailable,
        setCurrentInfosetByID: actions.infosets.setCurrentInfosetByID,
        addMemberToInfoset: actions.infosets.addMemberToInfoset,
        removeMemberFromInfoset: actions.infosets.removeMemberFromInfoset,
        setSearchMemberTerm: actions.infosets.setSearchMemberTerm,
        setOrderToMembersAvailable: actions.infosets.setOrderToMembersAvailable,
    }), {
        name: 'InfosetsMemberMenuContainer',
        components: {
            InfosetMembersMenu,
        },
    }))
], InfosetsMemberMenuContainer);
export default InfosetsMemberMenuContainer;
