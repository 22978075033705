var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Vue, Component, Prop, Emit, Watch } from 'vue-property-decorator';
import Input from 'components/Input.vue';
import { differenceBy } from 'lodash';
import { json } from 'overmind';
let InfosetMembersMenu = class InfosetMembersMenu extends Vue {
    constructor() {
        super(...arguments);
        this.filteredAvailableMembers = [];
        this.selected = [];
        this.showMemberDetails = false;
        this.currentMember = {};
        this.loading = true;
        this.members = [];
        this.memberSearch = '';
        this.countMembers = this.infosetMembers.length;
        this.chipMemberPosition = {
            x: 0,
            y: 0,
        };
    }
    mounted() {
        this.initializeMembers();
    }
    initializeMembers() {
        this.members = differenceBy(json(this.availableMembers), json(this.infosetMembers), 'id');
    }
    onAvailableMembersChange() {
        this.loading = false;
        this.initializeMembers();
    }
    onInfosetMembersChanged(val, oldVal) {
        this.countMembers = val && val.length;
        this.initializeMembers();
    }
    limitMembersByTypeUser(countMembers) {
        // @ts-ignore TS2349: property inexistent
        if (this.$auth.check(['admin', 'owner', 'manager', 'customer_success'])) {
            return countMembers >= 5;
        }
        else {
            return countMembers >= 4;
        }
    }
    showMember(member, index) {
        this.currentMember = member;
        this.showMemberDetails = true;
        const rectValues = this.$refs.chipMembers[index].$el.getBoundingClientRect();
        this.chipMemberPosition.x = rectValues.x;
        this.chipMemberPosition.y = rectValues.y;
    }
    memberAdded(memberId) {
        return this.infosetMembers.findIndex(m => m.id === memberId) > -1;
    }
    searchMembers(term) {
        return term;
    }
    changeSelected(member, event) {
        if (event) {
            this.countMembers++;
        }
        else {
            this.countMembers--;
        }
    }
    containInChangeble(member) {
        return this.infosetMembers.find(m => m.id === member.id) !== undefined;
    }
    notFullList(member) {
        let maxCount = 4;
        // @ts-ignore
        if (this.$auth.check(['admin', 'owner', 'manager', 'customer_success'])) {
            maxCount = 5;
        }
        if (this.countMembers >= maxCount) {
            if (this.containInChangeble(member))
                return true;
            return false;
        }
        return true;
    }
    updateMembers(member, event) {
        this.changeSelected(member, event);
        return { member, event };
    }
    requestMembers() {
        return true;
    }
    closeMenu() {
        return true;
    }
    showMenu() {
        return true;
    }
};
__decorate([
    Prop({ default: () => [] })
], InfosetMembersMenu.prototype, "availableMembers", void 0);
__decorate([
    Prop({ default: () => [] })
], InfosetMembersMenu.prototype, "infosetMembers", void 0);
__decorate([
    Prop({ default: '' })
], InfosetMembersMenu.prototype, "infosetName", void 0);
__decorate([
    Prop({ default: () => { } })
], InfosetMembersMenu.prototype, "currentUser", void 0);
__decorate([
    Watch('availableMembers')
], InfosetMembersMenu.prototype, "onAvailableMembersChange", null);
__decorate([
    Watch('infosetMembers')
], InfosetMembersMenu.prototype, "onInfosetMembersChanged", null);
__decorate([
    Emit('searchMembers')
], InfosetMembersMenu.prototype, "searchMembers", null);
__decorate([
    Emit('change')
], InfosetMembersMenu.prototype, "updateMembers", null);
__decorate([
    Emit('requestMembers')
], InfosetMembersMenu.prototype, "requestMembers", null);
__decorate([
    Emit('closeMenu')
], InfosetMembersMenu.prototype, "closeMenu", null);
__decorate([
    Emit('showMenu')
], InfosetMembersMenu.prototype, "showMenu", null);
InfosetMembersMenu = __decorate([
    Component({
        components: {
            Input,
        },
    })
], InfosetMembersMenu);
export default InfosetMembersMenu;
