var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { format, formatDistance } from 'date-fns';
import ptBrLocale from 'date-fns/locale/pt-BR';
import EmptyMessage from '@/components/EmptyMessage.vue';
import { json } from 'overmind';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
let InfosetsExportations = class InfosetsExportations extends Vue {
    constructor() {
        super(...arguments);
        this.expanded = [];
        this.rowTab = 'summary';
        this.columnsList = [
            {
                name: 'date',
                label: 'Data de solicitação',
                align: 'left',
                field: 'date',
            },
            {
                name: 'infoset',
                label: 'Nome da Lista',
                align: 'left',
                field: 'infoset',
            },
            {
                name: 'service',
                label: 'Destino',
                align: 'left',
                field: 'service',
            },
            {
                name: 'companies',
                label: 'Empresas',
                align: 'center',
                field: 'companies',
            },
            {
                name: 'status',
                label: 'Status',
                align: 'left',
                field: 'status',
            },
        ];
        this.exportsData = [];
    }
    onExportationsChange() {
        this.exportsData = json(this.exportations.map((exportation, index) => {
            const companies = exportation.statistics
                ? exportation.statistics.totals.companies
                : 0;
            return {
                index,
                id: exportation.id,
                date: this.getDistanceDateFromToday(exportation.createdAt),
                service: exportation.service,
                status: exportation.status,
                counting_successful_companies: exportation.counting_successful_companies,
                count_of_companies_with_errors: exportation.count_of_companies_with_errors,
                counting_successful_decisors: exportation.counting_successful_decisors,
                count_of_decisors_with_errors: exportation.count_of_decisors_with_errors,
                companies: companies,
                infoset: exportation.infoset,
                user: exportation.user,
                statistics: exportation.statistics,
                createdAt: exportation.createdAt,
                tab: `summary-${exportation.id}`,
            };
        }));
    }
    getDistanceDateFromToday(date) {
        const today = new Date();
        const eventDate = new Date(date);
        return formatDistance(eventDate, today, {
            addSuffix: true,
            includeSeconds: true,
            locale: ptBrLocale,
        });
    }
    getExportationStatusColor(row) {
        switch (row.status) {
            case 'export_companies_initialized':
                return 'grey';
            case 'export_companies_in_progress':
                return 'yellow';
            case 'export_companies_error':
                return 'red';
            case 'export_companies_cancelled':
                return 'grey';
            case 'export_companies_completed':
                return 'green';
            default:
                return 'grey';
        }
    }
    getExportationStatus(row) {
        switch (row.status) {
            case 'export_companies_initialized':
                return 'Inicializado';
            case 'export_companies_in_progress':
                return 'Em andamento';
            case 'export_companies_cancelled':
                return 'Cancelado';
            case 'export_companies_error':
                return 'Erro';
            case 'export_companies_completed':
                return 'Finalizado';
            default:
                return 'Inicializado';
        }
    }
    getProgressPercentage(row) {
        let total = 0;
        let processed = 0;
        if (row.service.includes('excel') || row.service.includes('csv')) {
            if (row.statistics && row.statistics.totals)
                if (row.service.includes('emails')) {
                    total = row.statistics.totals.emails || 0;
                }
                else if (row.service.includes('decisors')) {
                    total = row.statistics.totals.decisors || 0;
                }
                else {
                    total = row.statistics.totals.companies || 0;
                }
            if (row.statistics && row.statistics.processed) {
                const companies = row.statistics.processed.companies || 0;
                const rows = row.statistics.processed.rows || 0;
                processed = companies + rows;
            }
        }
        else {
            if (!!row.statistics &&
                !!row.statistics.processed &&
                !!row.statistics.processed.companies &&
                !!row.statistics.processed.decisors) {
                total = row.statistics.totals.companies + row.statistics.totals.decisors;
                processed =
                    row.statistics.processed.companies + row.statistics.processed.decisors;
            }
            else {
                total = 0;
                processed = 0;
            }
        }
        return Math.round((processed / total) * 100) || 0;
    }
    getExportationTime(row) {
        return format(new Date(row.createdAt), 'HH:mm');
    }
    getExportationDate(row) {
        return format(new Date(row.createdAt), 'dd/MM/yyyy');
    }
    getExportationAuthor(row) {
        return row.user && row.user[0] ? row.user[0].name : '';
    }
    totalCompaniesWithSuccess(row) {
        return row.counting_successful_companies;
    }
    totalCompaniesWithErrors(row) {
        return row.count_of_companies_with_errors;
    }
    handleServiceName(service) {
        return service;
    }
    handleInfosetName(row) {
        return row.infoset && row.infoset[0] && row.infoset[0].name
            ? row.infoset[0].name
            : '';
    }
    totalDecisorsWithSuccess(row) {
        return row.counting_successful_decisors;
    }
    totalDecisorsWithErrors(row) {
        return row.count_of_decisors_with_errors;
    }
    companiesWithErrors(row) {
        return row.count_of_companies_with_errors;
    }
    decisorsWithErrors(row) {
        //@ts-ignore
        return row.result.success.flatMap(company => company.decisors.errors);
    }
    exportationTotalErrors(row) {
        const total = row.count_of_companies_with_errors + row.count_of_decisors_with_errors;
        if (total > 99) {
            return '+99';
        }
        return total.toString();
    }
    cancelExportation(eventId) {
        return this.$emit('cancelExportation', eventId);
    }
    exportationNotCancelled(row) {
        return row.status !== 'export_companies_cancelled';
    }
    exportationNotCompleted(row) {
        return row.status !== 'export_companies_completed';
    }
    exportationNotHasError(row) {
        return row.status !== 'export_companies_error';
    }
    shouldShowCancelExportationButton(row) {
        return (
        // this.getProgressPercentage(row) !== 100 &&
        this.exportationNotCancelled(row) &&
            this.exportationNotCompleted(row) &&
            this.exportationNotHasError(row));
    }
};
__decorate([
    Prop({ default: () => { } })
], InfosetsExportations.prototype, "exportations", void 0);
__decorate([
    Watch('exportations')
], InfosetsExportations.prototype, "onExportationsChange", null);
InfosetsExportations = __decorate([
    Component({
        components: {
            EmptyMessage,
        },
    })
], InfosetsExportations);
export default InfosetsExportations;
