var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Vue, Component, Watch } from 'vue-property-decorator';
import Modal from 'components/Modal.vue';
import { json } from 'overmind';
import InnerLoading from '@/components/InnerLoading.vue';
import { AnswerSurveyStatus, SurveyAction } from '@/overmind/users/state';
import SurveyImageHeader from './SurveyImageHeader.vue';
import SurveyVoucher from './SurveyVoucher.vue';
import { mixpanelTracking } from '@/services/mixpanel';
import { connect } from '@/overmind';
let SurveyModalPopUpContainer = class SurveyModalPopUpContainer extends Vue {
    constructor() {
        super(...arguments);
        this.openSurveyModal = false;
        this.rating = -1;
        this.showSurveyTitle = false;
        this.confirmBtnClicked = false;
        this.errorSavingUserRating = false;
        this.ratingClicked = false;
        this.selectedSection = 'main';
        this.loadingModal = false;
        this.btnLoading = false;
        this.comment = '';
        this.colors = [
            '#F3705A',
            '#F3705A',
            '#F3705A',
            '#FF7A00',
            '#FF7A00',
            '#FF7A00',
            '#FFB800',
            '#EDD500',
            '#9FCE19',
            '#19CE8D',
            '#19C1CE',
        ];
    }
    onCreateAttemptsLogStatus() {
        //@ts-ignore
        if (this.createAttemptsLogStatus.success)
            this.loadingModal = false;
    }
    onAnswerSurveyStatus() {
        if (
        //@ts-ignore
        this.answerSurveyStatus === AnswerSurveyStatus.CREATED &&
            !!this.comment)
            this.resetSurveyStateInformations();
        this.errorAnswerSurvey();
    }
    async mounted() {
        // @ts-ignore
        const result = await this.modalActions.getShowSurveyModal();
        if (result) {
            // @ts-ignore
            await this.actions.getAllSurveysQuestions();
            this.openSurveyModal = true;
        }
    }
    async closeModal() {
        this.btnLoading = true;
        //@ts-ignore
        const result = await this.actions.createAttemptsLog(SurveyAction.CLOSE);
        if (result) {
            //@ts-ignore
            this.openSurveyModal = await this.modalActions.getShowSurveyModal();
            // this.actions.getShowSurveyModalFake()
            mixpanelTracking('Survey: Fechou o survey');
        }
        this.btnLoading = false;
    }
    resetSurveyStateInformations() {
        this.rating = -1;
        this.showSurveyTitle = false;
        this.confirmBtnClicked = false;
        this.errorSavingUserRating = false;
        this.ratingClicked = false;
        this.comment = '';
    }
    handleRatingClicked() {
        this.ratingClicked = true;
        this.showConfirmMessage();
    }
    showConfirmMessage() {
        if ((this.ratingClicked && this.rating === -1) || this.rating === null)
            return false;
        else if (this.ratingClicked &&
            this.rating !== -1 &&
            this.confirmBtnClicked) {
            this.errorSavingUserRating = false;
            return false;
        }
        else {
            return true;
        }
    }
    async answerSurvey() {
        // @ts-ignore
        const question = json(this.surveyQuestion)[0];
        if (this.rating !== -1) {
            const survey = {
                rating: this.rating,
                survey_question_id: question._id.$oid,
                type: question.type,
                comment: this.comment,
            };
            //@ts-ignore
            await this.actions.answerSurvey({ survey });
            //@ts-ignore
            await this.actions.createAttemptsLog(SurveyAction.SUBMIT);
        }
    }
    confirmRating() {
        this.confirmBtnClicked = true;
        if ([9, 10].includes(this.rating)) {
            this.loadingModal = true;
            this.answerSurvey();
            this.loadingModal = false;
            this.setSection('finish');
        }
        else {
            this.setSection('comments');
        }
    }
    finish() {
        // @ts-ignore
        this.modalActions.closeModal();
        this.openSurveyModal = false;
    }
    sendSugestions() {
        this.loadingModal = true;
        this.answerSurvey();
        this.loadingModal = false;
        this.setSection('finish');
    }
    errorAnswerSurvey() {
        //@ts-ignore
        if (this.answerSurveyStatus === AnswerSurveyStatus.ERROR)
            this.errorSavingUserRating = true;
    }
    setRating(value) {
        if (this.rating === value && this.ratingClicked) {
            this.ratingClicked = false;
            this.confirmBtnClicked = false;
            this.rating = -1;
        }
        else {
            this.ratingClicked = true;
            this.confirmBtnClicked = true;
            return (this.rating = value);
        }
    }
    setSection(value) {
        this.btnLoading = false;
        return (this.selectedSection = value);
    }
    showSection(value) {
        return this.selectedSection === value;
    }
    disableRatingBtn(value) {
        if (this.ratingClicked) {
            if (this.rating !== value) {
                return 'btn-disabled';
            }
        }
        return;
    }
    setError() {
        return this.setSection('error');
    }
    resetLoading() {
        setTimeout(() => {
            this.loadingModal = false;
        }, 2000);
    }
};
__decorate([
    Watch('createAttemptsLogStatus', { immediate: true })
], SurveyModalPopUpContainer.prototype, "onCreateAttemptsLogStatus", null);
__decorate([
    Watch('answerSurveyStatus', { immediate: true })
], SurveyModalPopUpContainer.prototype, "onAnswerSurveyStatus", null);
__decorate([
    Watch('errorSavingUserRating')
], SurveyModalPopUpContainer.prototype, "setError", null);
SurveyModalPopUpContainer = __decorate([
    Component(connect(({ actions, state }) => ({
        actions: actions.users,
        modalActions: actions.modals,
        modalState: state.modals,
        surveyQuestion: state.users.surveyQuestion,
        surveyQuestionStatus: state.users.surveyQuestionStatus,
        answerSurveyStatus: state.users.answerSurveyStatus,
        createAttemptsLogStatus: state.users.createAttemptsLogStatus,
        rescueVoucherStatus: state.users.rescueVoucherStatus,
        surveyInformation: state.users.surveyInformation,
        showSurveyModal: state.users.showSurveyModal,
    }), {
        components: {
            Modal,
            InnerLoading,
            SurveyImageHeader,
            SurveyVoucher,
        },
    }))
], SurveyModalPopUpContainer);
export default SurveyModalPopUpContainer;
