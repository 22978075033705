var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Vue, Prop, Component } from 'vue-property-decorator';
import { connect } from '@/overmind';
import InfosetsOverviewItem from '../components/InfosetsOverviewItem.vue';
import InfosetsOverviewLabelsSkeleton from './infosetsOverviewLabelsSkeleton.vue';
import { isEmpty } from 'lodash';
import { json } from 'overmind';
import moment from 'moment';
import Tooltip from '../../../components/Tooltip.vue';
import SpeedioAcademyBanner from '@/modules/infosets/components/SpeedioAcademy/Banner.vue';
import SurveyBanner from '@/modules/infosets/components/SurveyBanner/Banner.vue';
let InfosetsOverview = class InfosetsOverview extends Vue {
    constructor() {
        super(...arguments);
        this.overviewItems = {
            initiated: {
                name: 'iniciados',
                desc: 'Empresas com prospecção iniciada dividido pelo total de empresas',
            },
            interested: {
                name: 'interessados',
                desc: 'Empresas que demonstraram interesse dividido pelo total de empresas',
            },
            successes: {
                name: 'com sucesso',
                desc: 'Empresas que se obteve sucesso dividido pelo total de empresas',
            },
        };
        this.availableUsers = [];
        // @ts-ignore
        this.selectedUserName = this.$auth.user().name;
    }
    overviewByMember(userId) {
        this.selectUser(userId);
        this.$emit('overviewByMember', userId);
    }
    get progress() {
        let progress = 0;
        if (this.currentInfoset.contacts > 0) {
            progress = Math.round(((this.overview.future_interest +
                this.overview.discarded +
                this.overview.successes) *
                100) /
                this.currentInfoset.contacts);
        }
        return progress;
    }
    notLoadedInfoset() {
        if (isEmpty(this.currentInfoset)) {
            return true;
        }
        else {
            return false;
        }
    }
    get getUpdatedDate() {
        moment.locale('pt-BR');
        return this.currentInfoset.updatedAt
            ? moment(new Date(this.currentInfoset.updatedAt), 'YYYYMMDD').fromNow()
            : '';
    }
    get getAvailableUsers() {
        if (this.currentInfoset && this.currentInfoset.author) {
            if ((this.currentInfoset.author.id && this.currentInfoset.author.name) ||
                this.currentInfoset.author.email) {
                const { id, name, email } = this.currentInfoset.author;
                const authorUser = {
                    id,
                    name,
                    email,
                };
                if (!isEmpty(authorUser) &&
                    !isEmpty(this.currentInfoset) &&
                    !isEmpty(this.currentInfoset.members)) {
                    if (authorUser.id == this.currentInfoset.members[0].id) {
                        this.availableUsers = [
                            authorUser,
                            ...json(this.currentInfoset.members.slice(1)),
                        ];
                    }
                    else {
                        this.availableUsers = [
                            authorUser,
                            ...json(this.currentInfoset.members),
                        ];
                    }
                }
            }
        }
        return this.availableUsers;
    }
    selectUser(userId) {
        this.selectedUserName = this.availableUsers.find(u => u.id === userId).name;
    }
    get handleSelectedUserName() {
        if (this.selectedUserName) {
            return `Visualizando como ${this.selectedUserName}`;
        }
        return 'Visualizar como';
    }
    totalInterested(overview) {
        return overview.interested + overview.initiated;
    }
    totalSuccess(overview) {
        return overview.successes + overview.interested;
    }
    get surveyGreaterThanSeven() {
        const expireDate = new Date(2023, 0, 31);
        const dateNow = new Date();
        //@ts-ignore
        if (dateNow <= expireDate && !!this.state.surveyGreaterThanSeven) {
            return true;
        }
        else {
            return false;
        }
    }
    mounted() {
        //@ts-ignore
        if (this.state.surveyGreaterThanSeven === undefined) {
            //@ts-ignore
            return this.actions.getUserSurveyGreaterThanSeven();
        }
    }
};
__decorate([
    Prop({ default: () => { } })
], InfosetsOverview.prototype, "overview", void 0);
__decorate([
    Prop({ default: () => { } })
], InfosetsOverview.prototype, "currentInfoset", void 0);
__decorate([
    Prop({ default: false })
], InfosetsOverview.prototype, "loadingOverview", void 0);
__decorate([
    Prop({ default: false })
], InfosetsOverview.prototype, "loadingOverviewFromIfosetContainer", void 0);
InfosetsOverview = __decorate([
    Component(connect(({ state, actions }) => ({
        actions: actions.users,
        state: state.users,
    }), {
        components: {
            InfosetsOverviewItem,
            InfosetsOverviewLabelsSkeleton,
            Tooltip,
            SpeedioAcademyBanner,
            SurveyBanner,
        },
    }))
], InfosetsOverview);
export default InfosetsOverview;
