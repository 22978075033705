var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Modal from '@/components/Modal.vue';
import OnboardStepItem from '@/components/Onboarding/OnboardStepItem.vue';
import { handleRandomValuesRange } from '@/shared/utils/helpers';
import { Component, Vue, Watch } from 'vue-property-decorator';
import IconBlueSuccess from '../Icons/icon-blue-success.vue';
import { connect } from '@/overmind';
let OnboardingContainer = class OnboardingContainer extends Vue {
    constructor() {
        super(...arguments);
        this.showOnboardModal = false;
        this.selectedOption = 0;
        this.refusedOnboarding = false;
        this.slide = 1;
        this.comment = '';
        this.successUserPage = false;
        this.onboardingSteps = {
            1: {
                title: 'Bem vindo(a) ao time que mais vende no Brasil!',
                message: 'Antes de começar a usar a plataforma, que tal aprender <b>nossas funcionalidades?</b>',
            },
            2: {
                title: 'Bem vindo(a) ao time que mais vende no Brasil!',
                message: 'Antes de começar a usar a plataforma, que tal aprender <b>nossas funcionalidades?</b>',
            },
            3: {
                title: 'Bem vindo(a) ao time que mais vende no Brasil!',
                message: 'Antes de começar a usar a plataforma, que tal aprender <b>nossas funcionalidades?</b>',
            },
        };
    }
    showTextArea() {
        return this.selectedOption === 3
            ? (this.refusedOnboarding = true)
            : (this.refusedOnboarding = false);
    }
    async mounted() {
        // @ts-ignore
        this.showOnboardModal = await this.modalActions.showOnBoardingModal();
    }
    selectOption(value) {
        if (this.selectedOption === value) {
            return (this.selectedOption = 0);
        }
        else
            return (this.selectedOption = value);
    }
    async confirmation() {
        if (this.selectedOption === 2)
            this.handleRandomCalendyUrls();
        this.successUserPage = true;
        const option = this.handleOptionSelected(this.selectedOption);
        // @ts-ignore TS2349: property inexistent
        await this.actions.onboardingRegister({
            comment: this.comment,
            selectedOption: option,
        });
        // @ts-ignore
        await this.actions.showOnboardingNotification();
    }
    handleOptionSelected(val) {
        return val === 1 ? 'already_done' : val === 2 ? 'scheduled' : 'refused';
    }
    async rememberMeLater() {
        // @ts-ignore TS2349: property inexistent
        await this.actions.rememberMeLater(true);
        this.closeModal();
    }
    handleRandomCalendyUrls() {
        const urls = [
            'https://speedio.com.br/agenda/gabriel-luz/',
            'https://calendar.google.com/calendar/u/0/appointments/AcZssZ0rnU3pM6P-Kjwz_Uc7wyxAeDu-w_Z4tqoKWvU=',
            'https://calendar.google.com/calendar/u/0/appointments/AcZssZ3E90BF5U479E50umiv2firopy3PL0H2QxOKkA=',
            'https://calendar.google.com/calendar/u/0/appointments/AcZssZ2cv13NF5wHfCqEqTQHDuWu8_zLlpQ4Vbn5KCU=',
            'https://speedio.com.br/agenda/matheus-miranda/',
            'https://calendar.google.com/calendar/u/0/appointments/AcZssZ3rNPBRX37KGdMKl4PIpCtiypLOnd64_jJcqzA=',
            'https://calendar.google.com/calendar/u/0/appointments/AcZssZ2nDjuw4ibkwYlJzzJp4Ln2-gPjcuLxUlGTdCY=',
        ];
        const redirectTo = urls[handleRandomValuesRange(0, urls.length)];
        window.open(redirectTo, '_blank');
    }
    closeModal() {
        // @ts-ignore TS2349: property inexistent
        this.modalActions.closeModal();
        this.showOnboardModal = false;
    }
};
__decorate([
    Watch('selectedOption')
], OnboardingContainer.prototype, "showTextArea", null);
OnboardingContainer = __decorate([
    Component(connect(({ actions, state }) => ({
        actions: actions.users,
        modalActions: actions.modals,
        showOnBoardingModalStatus: state.users.showOnBoardingModalStatus,
        showOnBoardingModal: state.users.showOnBoardingModal,
        currentUser: state.users.currentUser,
    }), {
        name: 'OnboardingContainer',
        components: {
            Modal,
            OnboardStepItem,
            IconBlueSuccess,
        },
    }))
], OnboardingContainer);
export default OnboardingContainer;
