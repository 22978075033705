var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Vue, Component, Prop, PropSync, Watch } from 'vue-property-decorator';
import AccuracyDataFeedbackRange from './AccuracyDataFeedbackRange.vue';
import { connect } from '@/overmind';
let AccuracyDataFeedbackModal = class AccuracyDataFeedbackModal extends Vue {
    constructor() {
        super(...arguments);
        this.slider = 8;
        this.accuracyList = {
            Decisores: false,
            'E-mails': false,
            Telefone: false,
            Site: false,
            Outros: false,
        };
        this.description = '';
        this.completeFeedback = false;
    }
    getModalShow(val) {
        if (!val) {
            this.completeFeedback = false;
            return;
        }
    }
    resetData() {
        this.slider = 8;
        for (let each in this.accuracyList) {
            this.accuracyList[each] = false;
        }
        this.description = '';
    }
    get selectedAccuracyProblems() {
        const accuracyList = [];
        for (let each in this.accuracyList) {
            if (this.accuracyList[each]) {
                accuracyList.push(each);
            }
        }
        return accuracyList;
    }
    async sendFeedbackInformation() {
        const data = {
            info_set_id: this.infoset.id,
            data_category: this.selectedAccuracyProblems,
            data_accuracy: this.slider * 10,
            description: this.description,
        };
        this.completeFeedback = true;
        //@ts-ignore
        const sendFeedback = await this.actions.accuracyDataFeedback(data);
        if (sendFeedback.status == 200)
            this.resetData();
    }
};
__decorate([
    PropSync('open', { default: false })
], AccuracyDataFeedbackModal.prototype, "showModal", void 0);
__decorate([
    Prop({ default: () => { } })
], AccuracyDataFeedbackModal.prototype, "infoset", void 0);
__decorate([
    Watch('showModal')
], AccuracyDataFeedbackModal.prototype, "getModalShow", null);
AccuracyDataFeedbackModal = __decorate([
    Component(connect(({ actions }) => ({ actions: actions.infosets }), {
        components: {
            AccuracyDataFeedbackRange,
        },
    }))
], AccuracyDataFeedbackModal);
export default AccuracyDataFeedbackModal;
